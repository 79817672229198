<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Tickets registrados</h5>
				</a-col>
				<!-- <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="authorsHeaderBtns" size="small">
						<a-radio-button value="all">ALL</a-radio-button>
						<a-radio-button value="online">ONLINE</a-radio-button>
					</a-radio-group>
				</a-col> -->
				<!-- Filter by Correlativo-->
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-input
						placeholder="Buscar por correlativo"
						size="small"
						style="width: 200px;"
						v-model="search"
						@change="searchData"
					/>
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="false">

			<template slot="author" slot-scope="author">
				<div class="table-avatar-info">
					<!-- <a-avatar shape="square" :src="author.avatar" /> -->
					<div class="avatar-info">
						<h6>Nombre: {{ author.name }}</h6>
						<p>Correlativo: {{ author.correlativo }}</p>
						<p>Celular: {{ author.celular }}</p>
					</div>
				</div>
			</template>

			<template slot="func" slot-scope="func">
				<div class="author-info">
					Funk {{ func }}
					<h6 class="m-0">{{ func }}</h6>
					<p class="m-0 font-regular text-muted">{{ func.department }}</p>
				</div>
			</template>

			<template slot="boleta" slot-scope="boleta">
				{{ boleta }}
			</template>
			<template slot="status" slot-scope="mesa">
				<a-button class="tag-status" 
				:class="mesa.tipo=='Diamante' ? 'ant-tag-primary' : 'ant-tag-muted'"
				@click="()=>handleBoletaClick(mesa)"
				>
					{{ mesa.tipo}} # {{ mesa.numero }}
				</a-button>
			</template>

		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
			filter:
			{
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
				search: null,
				allTicketsData: []
			}
		},
		methods: {
			handleBoletaClick(mesa){
				console.log("Boleta clicked", mesa);
				if(mesa.tipo == 'Diamante'){
					this.$router.push({name: 'Dashboard', query: {mesa: mesa.numero}});
				}
			},
			searchData(){
				console.log("Searching data");
				console.log(this.search);
				this.filter(this.search);
			}
		}	
	})

</script>