<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">

			<!-- Authors Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Authors Table Card -->
				<CardAuthorTable
					:data="table1Data"
					:columns="table1Columns"
					:filter="filterTicketsByCorrelativo"
				></CardAuthorTable>
				<!-- / Authors Table Card -->

			</a-col>
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->

		<!-- Projects Table -->
		<a-row :gutter="24" type="flex">

			<!-- Projects Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Projects Table Column -->
				<!-- <CardProjectTable2
					:data="table2Data"
					:columns="table2Columns"
				></CardProjectTable2> -->
				<!-- / Projects Table Column -->

			</a-col>
			<!-- / Projects Table Column -->

		</a-row>
		<!-- / Projects Table -->

	</div>
</template>

<script>

	// "Authors" table component.
import CardAuthorTable from '../components/Cards/CardAuthorTable' ;

	// "Projects" table component.
	import CardProjectTable2 from '../components/Cards/CardProjectTable2' ;
	
	// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Nombre',
			dataIndex: 'author',
			scopedSlots: { customRender: 'author' },
		},
		{
			title: 'Boleta',
			dataIndex: 'boleta',
			scopedSlots: { customRender: 'boleta' },
		},
		{
			title: 'Mesa',
			dataIndex: 'mesa',
			scopedSlots: { customRender: 'status' },
		}
	];

	// "Authors" table list of rows and their properties.
	const table1Data = [
		{
			key: '1',
			author: {
				name: 'Cargando...',
				correlativo: 'Cargando...',
			},
			boleta: "Cargando ...",
			mesa: {
				tipo: "Diamante",
				numero: "1"
			}
		},
	];
	
	
	export default ({
		components: {
			CardAuthorTable,
			CardProjectTable2,
		},
		data() {
			return {
				// Associating "Authors" table data with its corresponding property.
				table1Data: table1Data,

				// Associating "Authors" table columns with its corresponding property.
				table1Columns: table1Columns,
			}
		},
		mounted() {
			console.log("Tables page loaded");
			this.fetchAllTickets();
		},
		methods: {
			formatTicketsData(){
				this.table1Data = []
				console.log("Formatting tickets data");
				console.log(this.allTicketsData);
				// convert this.allTicketsData to table1Data
				this.allTicketsData.forEach(ticket => {
					let ticketData = {
						key: ticket._id,
						author: {
							name: `${ticket.Nombres} ${ticket.Apellidos}`,
							correlativo: `${ticket.Correlativo}`,
							celular: `${ticket.celular_whatsapp}`
						},
						boleta: ticket.Boleta,
						mesa: {
							tipo: ticket.mesa.tipo,
							numero: ticket.mesa.numero,
						}
					}
					this.table1Data.push(ticketData);
				});
			},
			async fetchAllTickets(){
				console.log("Fetching all tickets");

				await this.$axios.get('/tickets?populate=*&pagination[limit]=10000').then(response => {
					console.log(response.data.data);
					this.allTicketsData = response.data.data;
					this.formatTicketsData();
					return response.data.data;
				}).catch(error => {
					console.log(error);
				});
			},
			// method to filter data by Correlativo
			async filterTicketsByCorrelativo(correlativo){
				await this.fetchAllTickets();
				if(correlativo){

					this.allTicketsData = this.allTicketsData.filter((ticket) => ticket.Correlativo.includes(correlativo));
					this.formatTicketsData();
				}
				else{
					// this.fetchAllTickets();
				}
			}
		}
	})

</script>

<style lang="scss">
</style>